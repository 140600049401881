import React, { Component } from 'react';
import { FaHotTub, FaHiking, FaVolleyballBall, FaGamepad, FaUmbrellaBeach } from 'react-icons/fa';
import { GiCampfire } from 'react-icons/gi';

import Title from './Title';

interface Service {
    icon: JSX.Element | string;
    title: string;
    info: string;
}

interface IStateServices {
    services: Service[];
}

export default class Services extends Component<{}, IStateServices> {

    public readonly state: Readonly<IStateServices> = {
        services: [
            {
                icon: <FaUmbrellaBeach />,
                title: "Water front",
                info: "Enjoy your own privat water access to the Muskoka River"
            },
            {
                icon: <GiCampfire />,
                title: "Safe Fire zone",
                info: "Roast marshmallows under the stars"
            },
            {
                icon: <FaHotTub />,
                title: "Hot Tub",
                info: "Boil away your worries while staring into the sunset over the lake"
            },
            {
                icon: <FaHiking />,
                title: "endless hiking",
                info: "Mintues away from dozens of hiking routes"
            },
            {
                icon: <FaVolleyballBall />,
                title: "Volley Ball nets",
                info: "On site volleyball net for your entertainment"
            },
            {
                icon: <FaGamepad />,
                title: "Fully stocked game room",
                info: "Ping pong table, Pool table, the best board games and everything you could ever hope for!"
            },
        ]
    }

    public render() {
        const { services } = this.state;
        return (
            <section className="services">
                <Title title="Amenities" />
                <div className="services-center">
                    {services.map((item: Service, index: number) => {
                        return (
                            <article key={index} className="service">
                                <span>{item.icon}</span>
                                <h6>{item.title}</h6>
                                <p>{item.info}</p>
                            </article>
                        )
                    })}
                </div>
            </section>
        )
    }
}
