import React, { Component, useState } from 'react';
import Title from './Title';
import LivingRoom from '../assets/images/livingroom2.jpg';
import LivingRoom2 from '../assets/images/livingroom2.jpg';
import kitchen from '../assets/images/kitchen.jpg';
import bed1 from '../assets/images/bed1.jpg';
import bed2 from '../assets/images/bed2.jpg';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';




const FeaturedRooms: React.FC = () => {
    const CottiePic = [
        {
            img: LivingRoom,
            txt: 'Living Room'
        },
        {
            img: LivingRoom2,
            txt: 'Living Room'
        },
        {
            img: kitchen,
            txt: 'kitchen'
        },
        {
            img: bed1,
            txt: 'Master Bedroom'
        },
        {
            img: bed2,
            txt: 'Bedroom'
        }
    ]


    return (    
          
        <div style = {{marginTop: 20}}>
            <Title title="Photos"/>
           
            <Carousel>
                
            {
                CottiePic.map((item, index)=>{
                    return( 
                    <div key = {index}>
                        <img src={item.img} />
                        <p className="legend" style = {{backgroundColor: 'transparent'}}>{item.txt}</p>
                    </div>
                        )
                })
            }
            </Carousel>
        </div>
        
    )
}

export default FeaturedRooms;