import React from 'react';
import { Link } from 'react-router-dom';

const Booknow: React.FC = () => {
    
    return (
        <>
            <Link to="/BookNow" className="btn-primary">Book now</Link>
        </>
    )
}

export default Booknow;
