import React from 'react';

const Booknow: React.FC = () => {
    return (
       
       <div style = {{background:'green', width:'100%', height: '100%'}}>
           <iframe src="https://www.availcalendar.com/print/10844" width="100%" height="100%" scrolling="yes">
            </iframe>
        </div>
       
     
        
    )
}

export default Booknow;