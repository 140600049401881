import React from 'react';
import Cover from '../components/Cover';
import Banner from '../components/Banner';

import Services from '../components/Services';
import FeaturedRooms from '../components/FeaturedRooms';
import Booknow from '../components/Booknow';
import Details from '../components/Details';
import Address from '../components/Address';

const Home: React.FC = () => {
    return (
        <>
            <Cover>
                <Banner 
                title="The Ultimate Cottage Getaway"
                subtitle="Bookings starting at $200/Night"
                >
                <Booknow/>
                </Banner>
            </Cover>
            <Services/>
            <FeaturedRooms />
            <Details/>
            
            <Address/>
        </>
    )
}

export default Home
