import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

const Details: React.FC = () => {
    return (
        <Col xs={12}>
        <Row center="xs" className="detailsSection">
            <Col xs={12} sm={1} md={2} lg={3} className="detailsSection_section">
                <br/>
                <h3>Details</h3>
                <p className= "detailsSection_section_info_text">
                    A beatiful 4 bedroom riverfront cottage located in the Bracebridge area that can accomdate up to 12 people.
                    Facility includes activities such a hot tub, volleyball net, access to water activies.  
                </p>
            </Col >
            <Col xs={12} sm={1} md={2} lg={2} className="detailsSection_section" >
                <br/>
                <h3>info</h3>
                <div className= "detailsSection_section_info">
                
                    <ol className= "detailsSection_section_info_text">
                        Price : 250-
                    </ol >
                    <ol className= "detailsSection_section_info_text">
                        Property Size : 1.74 acres
                    </ol>
                    <ol className= "detailsSection_section_info_text">
                        Capacity : fits up to 12
                    </ol>
                    <ol className= "detailsSection_section_info_text">
                        Pets Allowed!
                    </ol>
                </div>
            </Col >
            <Col xs={12} sm={1} md={2} lg={3} className="detailsSection_section">
                <br/>
                <h3>Contact us</h3>
                <ol className= "detailsSection_section_info_text">
                    Via Phone : 647-215-1099
                </ol >
                <ol className= "detailsSection_section_info_text">
                    Email: CottageBinx@gmail.com
                </ol>
            </Col >
        </Row>
        </Col>
    )
}

export default Details;



