import React from 'react';
import Title from './Title';
import mapImg from '../assets/images/cottageMap.png';

const handleOnclick =()=> {
    window.open('https://www.google.com/maps/place/1388+Carlsmount+Rd,+Bracebridge,+ON+P1L+1X1/@44.9964632,-79.2043071,16.25z/data=!4m5!3m4!1s0x4d2a82732f92f477:0x70ded209fa14ac5d!8m2!3d44.9961934!4d-79.203597')
}

const Address: React.FC = () => {      
        return (
            <div className="address"> 
                <div style = {{display: 'flex', flexDirection: 'column'}}>
                    
                        <Title title="Address" />
                    
                    <div >
                        <ol className= "address_text" >
                            1388 Carlsmount Rd
                        </ol>
                        <ol className= "address_text">
                            Bracebridge, ON P1L 1X1
                        </ol>
                        <ol className= "address_text">
                            P1L 1X1
                        </ol>
                    </div>
                    <img src={mapImg} className="detailsSection_section_img" onClick = {()=> handleOnclick()}/>
                </div>
               
                
        
            </div>
        )
    }
export default Address
