import React from 'react';
import '../assets/styles/sass/index.scss';
import { Route, Switch } from 'react-router-dom';

import Navbar from '../components/Navbar';
import Home from '../pages/Home';
import Booknow from '../pages/Booknow';
import ErrorPage from '../pages/ErrorPage';

const App: React.FC = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Booknow/" component={Booknow} />
        <Route component={ErrorPage} />
      </Switch>
    </React.Fragment>
  );
}

export default App;
